'use client'

import {notFound, redirect} from 'next/navigation'

import {Article} from 'nodes/article'
import {Case} from 'nodes/case'
import {DPFullNode} from 'types/DPTypes'
import {Dossier} from 'nodes/dossier'
import {Event} from 'nodes/event'
import {Job} from 'nodes/job'
import {PING_URL} from 'util/urls'
import {Page} from 'nodes/page'
import {Podcast} from 'nodes/podcast'
import {Press} from 'nodes/press'
import {Product} from 'nodes/product'
import {Researchproject} from 'nodes/researchproject'
import {Videopage} from 'nodes/videopage'
import unfetch from 'unfetch'
import {useEffect} from 'react'

export const DrupalPage: React.FC<{node: DPFullNode}> = ({node}) => {
	useEffect(() => {
		if ('_debug' in node) console.info(node._debug)

		if (node) {
			const pingUrl = PING_URL(node?.node_id)
			if (pingUrl) unfetch(pingUrl)
		}
	}, [node])

	// TODO: Replace as any
	if (!node?._type && Object.hasOwn(node, 'destination')) {
		redirect((node as any).destination)
	}

	if (!node?._type) return notFound()

	switch (node._type) {
		case 'page':
			return <Page {...node} />
		case 'case':
			return <Case {...node} />
		case 'event':
			return <Event {...node} />
		case 'press':
			return <Press {...node} />
		case 'article':
			return <Article {...node} />
		case 'product':
			return <Product {...node} />
		case 'research_project':
			return <Researchproject {...node} />
		case 'job':
			return <Job {...node} />
		case 'dossier':
			return <Dossier {...node} />
		case 'video_page':
			return <Videopage {...node} />
		case 'podcast':
			return <Podcast {...node} />
	}
	return null
}
